import React from "react"
import { graphql, StaticQuery } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroBanner from "../components/hero-banner"
import Slideshow100k from "../components/slideshow-100k"
import Img from "gatsby-image"
import { FaExternalLinkAlt } from "react-icons/fa"

export default ({ data }) => (
  <>
    <Layout selectedLink="6" footerFacebookLink="https://www.facebook.com/pages/category/Community/Kerry-100K-50K-Ultra-Marathon-479267599139260/">
      <SEO title="Tralee 100K Ultra Marathon 2025" description="Join us 21 June 2025 for the Tralee 100K Ultra Marathon" />
      <HeroBanner
        bgImage={data.image1.childImageSharp.fluid}
        title="Tralee 100K Ultra Marathon 2025"
        subTitle="21 June 2025"
        linkTitle="Register Today!"
        link="https://eventmaster.ie/event/6ZQ3fEpT5G/e12wsKkTr"
      />
      <section className="main-introduction">
        <div className="container-width">
          <div className="col-1">
            <p class="intro">The Tralee 100K Ultra Marathon will be one of Ireland's greatest tests of endurance.</p>
            <p>Are you ready to take up the challenge. The event starts 6 am outside the Aquadome in Tralee.</p>
          </div>
          <div className="col-2">
            <div className="fb-iframe">
              <iframe class="fb-iframe__iframe" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpages%2Fcategory%2FCommunity%2FKerry-100K-50K-Ultra-Marathon-479267599139260%2F&tabs=timeline&width=400&height=500&small_header=true&adapt_container_width=true&lazy=true&hide_cover=true&show_facepile=true&appId" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
          </div>
        </div>
      </section>

      <section className="faq">
        <h3>100K Ultra Marathon FAQ</h3>

        <div className="container-width">
          <div className="col-1">
            <h4>Is the race chip timed?</h4>
            <p>Yes, the Tralee Ultra Marathons are timed by <a href="http://www.timingsolutionsireland.com/">Timing Solutions Ireland</a>.</p>
          </div>
          <div className="col-2">
            <h4>Where can I collect my number and race pack?</h4>
            <p>
              There will be a number collection the night before. Details will be emailed the week before the run.
            </p>
          </div>
          <div className="col-1">
            <h4>Do you have a bag drop Facility?</h4>
            <p>
              Yes, collection at the start line and collection at the finish
              line.
            </p>
          </div>
          <div className="col-2">
            <h4>Where do the races start and finish?</h4>
            <p>The start and finish of the 100K ultra marathon is shown on the 10K loop map below.</p>
          </div>
          <div className="col-1">
          <h4>What time does the race start?</h4>
            <p>6:00 am</p>
          </div>
          <div className="col-2">
          <h4>What is the cost?</h4>
            <p>€80 per participant
            <br></br><small><em>* Additional bundle offers available at registration</em></small>
            </p>
          </div>
          <div className="col-1">
          <h4>What are the cut off times?</h4>
            <p>The Following Cut off Times will be in place :</p>
            <ul>
              <li>Causeway: 4hrs - 28K</li>
              <li>Ballyheigue: 8hrs - 52K</li>
              <li>Fenit: 12 hrs - 80K</li>
              <li>Tralee, Finish, Ashe Hall: 15hrs - 100K</li>
            </ul>
            <p>These cut off times will be strictly enforced. If you fail to meet a cut off time you will be informed you have been disqualified from the event. If you insist on continuing you will no longer come under the event insurance and will not receive a finishing time, medal or top. </p>
          </div>
          <div className="col-2">
            <h4>Is a crew required?</h4>
            <p>A crew is not compulsory, but we do recommend you have one, especially if you are a novice ultra runner.</p>
            <p>There will be drop bag zones approximately every 20K where participants can have their own supplies dropped. Please give your supplies to the Race Director at the start line. There will also be a mobile aid station on route. </p>
          </div>
        </div>
      </section>

      <section className="course">
        <h3>100K Ultra Marathon Course Map</h3>
        {/* <div className="button-subtle"><a href="https://youtu.be/MkQefhjbnU4" target="_blank">View Course Flythrough Video <FaExternalLinkAlt /></a></div> */}
        <figure>
          <div className="course__photo-holder">
            {/* <a
              href="https://www.mapmyrun.com/routes/view/1940811743"
              class="course__full-map-btn" target="_blank">
              View Interactive Map
              <FaExternalLinkAlt />
            </a> */}
            <Img
              className="course__photo"
              fluid={data.image3.childImageSharp.fluid}
              alt="Tralee 100K ultra marathon 2025 map"
            />
          </div>
          {/* <figcaption>
            <p className="text-align-center"><strong>100K:</strong> 10 loops</p>
          </figcaption> */}
        </figure>
      </section>



      <section className="regulations">
        <h3>Rules & Regulations</h3>
        <p><span>All rules have been established in order to protect the safety of our participant's.</span><span>Your cooperation is greatly appreciated in helping everyone to enjoy race day.</span></p>
        <div className="regulations__bullets">
          <ol>
            <li>Entry fees are non-refundable and non-transferable.</li>
            <li>
              Headphones are not permitted on the course for reasons of health &
              safety, both yours and other competitors. When you wear such a
              device you are unable to hear other competitors, cars, bicycles or
              stewards instructions.
            </li>
            {/* <li>All runners must complete the course in 3 hours.</li> */}
            <li>
              There are no road closures normal rules of the road apply, runners
              are requested to wear a High Viz vest, please follow any
              instructions from the stewards and Gardaí.
            </li>
            <li>Please be courteous to other road and pavement users.</li>
            <li>
              If you have to drop out, you should contact the race director.
            </li>
            <li>
              Event officials reserve the right, to modify, supplement or waive
              all or part of the event rules and decisions of the race director
              are final in all matters.
            </li>
            <li>
              Water and refreshments may be available around the course but
              overall runners and their crew are responsible for catering to
              their own needs.
            </li>
            <li>
              This is a Leave No Trace event please dispose of litter properly.
            </li>
            <li>
              If a runner is requested to retire from the event by medical staff
              they must do so.
            </li>
          </ol>
        </div>
      </section>

      <section className="photo-gallery">
        <h3>Photo Gallery</h3>
        <Slideshow100k />
      </section>
    </Layout>
  </>
)

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "100k-ultra-marathon-hero-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "logo-ultra-marathon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "100k-ultra-course-map.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 1820
          quality: 95
          srcSetBreakpoints: [960, 960, 960, 1280, 1820]
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
